import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "../firebase/credentials";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/bid-list",
    name: "BidList",
    component: () =>
      import(/* webpackChunkName: "bid-list" */ "../views/BidList.vue"),
  },
  {
    path: "/our-work/:id",
    name: "OurWork",
    component: () =>
      import(/* webpackChunkName: "our-work" */ "../views/OurWork.vue"),
  },
  {
    path: "/our-work/:id/:type",
    name: "OurWork Type",
    component: () =>
      import(/* webpackChunkName: "our-work" */ "../views/OurWork.vue"),
  },
  {
    path: "/project/:id",
    name: "ProjectID",
    component: () =>
      import(/* webpackChunkName: "project-edit-id" */ "../views/Project.vue"),
  },
  {
    path: "/all-projects",
    name: "All Projects",
    component: () =>
      import(/* webpackChunkName: "all-projects" */ "../views/AllProjects.vue"),
  },
  {
    path: "/project-edit",
    name: "ProjectEdit",
    component: () =>
      import(/* webpackChunkName: "project-edit" */ "../views/ProjectEdit.vue"),
    meta: { auth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/project-edit/:id",
    name: "ProjectEditID",
    component: () =>
      import(
        /* webpackChunkName: "project-edit-id" */ "../views/ProjectEdit.vue"
      ),
    meta: { auth: true },
  },
  {
    path: "/careers",
    name: "Careers",
    component: () =>
      import(/* webpackChunkName: "careers" */ "../views/Careers.vue"),
  },
  {
    path: "/careers/:id",
    name: "CareersID",
    component: () =>
      import(/* webpackChunkName: "careers-id" */ "../views/CareersID.vue"),
  },
  {
    path: "/career-edit",
    name: "CareerEdit",
    component: () =>
      import(/* webpackChunkName: "career-edit" */ "../views/CareerEdit.vue"),
    meta: { auth: true },
  },
  {
    path: "/career-edit/:id",
    name: "CareerEditID",
    component: () =>
      import(
        /* webpackChunkName: "career-edit-id" */ "../views/CareerEdit.vue"
      ),
    meta: { auth: true },
  },
  {
    path: "/applications",
    name: "Applications",
    component: () =>
      import(
        /* webpackChunkName: "applications" */ "../views/Applications.vue"
      ),
    meta: { auth: true },
  },
  {
    path: "/employees",
    name: "Employees",
    component: () =>
      import(/* webpackChunkName: "employees" */ "../views/Employees.vue"),
    meta: { auth: true },
  },
  {
    path: "/jlr-woodwork",
    name: "Woodshop",
    component: () =>
      import(/* webpackChunkName: "employees-id" */ "../views/Woodshop.vue"),
  },
  {
    path: "/employees/:id",
    name: "EmployeeID",
    component: () =>
      import(/* webpackChunkName: "employees-id" */ "../views/Employees.vue"),
    meta: { auth: true },
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to == null)
      console.log('')
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const requiredAuth = to.matched.some((x) => x.meta.auth);

  if (requiredAuth && !auth.currentUser) {
    next("/login");
  } else {
    next();
  }
});


export default router;
