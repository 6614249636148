import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

var config = {
  apiKey: process.env.VUE_APP_APIKEY,
  appId: process.env.VUE_APP_APPID,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  projectId: process.env.VUE_APP_PROJECTID,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
};

firebase.initializeApp(config);

const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();

// collection ref

const applicationsCollection = db.collection("applications");
const projectsCollection = db.collection("projects");
const positionsCollection = db.collection("job-positions");
const employeesCollection = db.collection("employees");
const usersCollection = db.collection("employees");

export {
  db,
  applicationsCollection,
  projectsCollection,
  positionsCollection,
  employeesCollection,
  usersCollection,
  storage,
  auth,
};
