<template>
  <div class="d-flex align-center">
    <router-link
      v-for="link in links"
      :key="link.i"
      :to="link.to"
      @contextmenu="link.title == 'Our Work' ? show : ''"
      class="px-2 title font-weight-light"
    >
      {{ link.title }}
    </router-link>
    <v-menu dark offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-card
          flat
          width="100%"
          text
          class="title font-weight-light px-2 py-2"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          dark
          color="transparent"
        >
          Our Work
        </v-card>
      </template>
      <v-list class="py-0">
        <v-list-item
          :to="item.to"
          class="py-1"
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title class="title font-weight-light">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <router-link
      to="/bid-list"
      class="px-2 title font-weight-light text-no-wrap"
    >
      Sub List
    </router-link>
    <router-link
      v-if="!isAuthenticated"
      to="/login"
      class="px-2 title font-weight-light"
    >
      Login
    </router-link>
    <p
      id="logout"
      @click="logout"
      class="title my-auto white--text font-weight-light px-2"
      v-else
    >
      Logout
    </p>
    <v-btn color="primary" class="ml-2" to="/contact" rounded outlined>
      Contact Us
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      items: [
        { title: "Residential / Remodel", to: "/our-work/residential" },
        { title: "Commercial / Remodel", to: "/our-work/commercial" },
        { title: "Public Works / Industrial", to: "/our-work/public-works" },
        {
          title: "Architectural Woodwork",
          to: "/our-work/architectural-woodwork",
        },
      ],
      right: null,
      links: [
        // {
        //   title: "Woodwork",
        //   to: "/jlr-woodwork",
        // },
        {
          title: "About",
          to: "/about",
        },
        {
          title: "Careers",
          to: "/careers",
        },
      ],
    };
  },
  computed: {
    ...mapState(["userProfile", "isAuthenticated"]),
  },
  methods: {
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: white !important;
}

#logout {
  cursor: pointer;
}
</style>
