import Vue from "vue";
import Vuex from "vuex";
import { auth } from "../firebase/credentials.js";
import { usersCollection } from "../firebase/credentials.js";
import * as fb from "../firebase/credentials.js";
import router from "../router/index.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userProfile: {},
    isAuthenticated: false,
    socialsContractors: [
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/jlrcontractors",
      },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/jlrcontractors/",
      },
      {
        icon: "mdi-youtube",
        link: "https://www.youtube.com/channel/UC-wkosCfsFxB1gGfjfxurZA",
      },
    ],
    socialsWoodwork: [
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/jlrfondell/",
      },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/jlrwoodwork/",
      },
      {
        icon: "mdi-youtube",
        link: "https://www.youtube.com/channel/UC-wkosCfsFxB1gGfjfxurZA",
      },
    ],
    logoSVG: `<svg viewBox="0 0 282.5 172.113">
  <path class="logo-svg" d="M140.306,172.113h0L73.617,106.251,18.875,160.313,0,139.809,140.307,0,282.5,139.809l-20.763,20.5L207,106.251l-66.689,65.862Zm.343-135.307L93.169,83.7l47.48,46.891L188.129,83.7l-47.48-46.893Z" />
</svg>
`,
    tags: {
      residential: {
        sub: "",
        p: [
          `Finally, there is a construction organization that understands your needs and is willing to listen.
      JLR Contractors has a long history of creating comfortable and compatible residential living space.  Whether new
      construction or a remodel, there are details aplenty.  We are confident that we can help you make the decisions
      that you need to achieve the highest possible result.`,
          `Take some time to view some of our previous projects.`,
        ],
      },
      residentialSub: "",
      commercial: {
        sub: "Expect quality craftsmanship in your commercial entities",
        p: [
          `Expect quality craftsmanship in your commercial entities.  JLR Contractors provides the highest level
       of attention to detail that makes your location unique. Whether you are building a wood enameled boutique or an 
       open dance floor–we are confident that we can meet your needs and provide the highest value for you.`,
          `In today's market, it takes careful planning, cooperative partnerships, and the right market data to be successful 
      in new or remodel retail construction projects. We suggest that the commercial developer should research the history
       of a selected construction site before they decide to do business at that location. When that decision is complete, 
       JLR can help to design, organize and create the environment you require for your commercial success.`,
        ],
      },
      commercialSub: "Expect quality craftsmanship in your commercial entities",
      publicWorks: {
        sub: "",
        p: [
          `We live in a community.`,
          `As we organize our living communities, we find that we need to have comfortable and enjoyable surroundings.  Parks,
       green zones, auto/pedestrian medians, cityscapes all require a level of quality and attention to detail.  JLR 
       Contractors works to create the foundation of strength beneath the surface as well as the beauty in the final, visible result.`,
          `Skate parks, ball parks, community centers, water flow diversion are all in the capabilities and resume for JLR Contractors.`,
          `Contact us for more information on how we can help you achieve all of your goals associated with your current and future public works projects.`,
        ],
      },
      publicWorksSub: "",
      architecturalwoodwork: {
        sub: "",
        p: [
          `<span class="primary--text">The Passion, Possibiliies and Perfection of Woodworking.</span>`,
          `The woodwork team has engineered, manufactured and installed every type of architectural woodwork
            for over 37 years, leading the industry by providing the latest wood grains to customers while producing
            a world-class product.`,
          `With our 40,000 square foot woodworking facility, located in Provo, UT, we are able to spend the necessary time
            in the early stages to minimize the cost and create a product that matches the architects/designers conceptual
            drawings while our three phases of <span class="primary--text">millwork, assembly, and finish</span>, are aided by the 
            latest in technology. The result? A process that enables perfection; expertise that produces world-class woodwork.`,
        ],
      },
      architecturalwoodworkSub: "",
    },
  },
  mutations: {
    setUserProfile(state, val) {
      state.isAuthenticated = val.email != undefined ? true : false;
      state.userProfile = val;
    },
  },
  actions: {
    async register({ dispatch }, form) {
      const { user } = await auth.createUserWithEmailAndPassword(
        form.email,
        form.password
      );

      console.log(dispatch);

      await usersCollection.doc(user.uid).set({
        name: form.name,
        email: form.email,
      });
    },

    async login({ dispatch }, form) {
      const { user } = await auth.signInWithEmailAndPassword(
        form.email,
        form.password
      );

      dispatch("fetchUserProfile", user);

      router.push("/");
    },

    async fetchUserProfile({ commit }, user) {
      const userProfile = await usersCollection.doc(user.uid).get();

      commit("setUserProfile", userProfile.data());

      router.push("/");
    },

    async logout({ commit }) {
      await fb.auth.signOut();
      commit("setUserProfile", {});
      router.push("/").catch(() => {});
    },
  },
  modules: {},
});
