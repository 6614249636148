import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { auth } from "./firebase/credentials.js";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

Vue.use(VueMeta);

let app;

auth.onAuthStateChanged((user) => {
  if (user && !store.state.isAuthenticated) {
    store.state.isAuthenticated = true;
    store.state.userProfile.email = user.email;
  }
  if (!app) {
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
