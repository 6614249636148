<template>
  <v-footer class="pa-10 grey darken-2">
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="d-flex flex-column"
        :class="$vuetify.breakpoint.mdAndUp ? '' : 'text-center'"
      >
        <v-img
          src="@/assets/JLR-Horizontal-White.svg"
          max-width="300"
          contain
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'mx-auto'"
        />
        <div>
          <v-icon
            size="40"
            color="white"
            class="mx-2"
            v-for="social in socials"
            :key="social.i"
            @click="openSocial(social.link)"
          >
            {{ social.icon }}
          </v-icon>
        </div>
        <p class="pt-4 white--text">© 2022 JLR Contractors</p>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.mdAndUp" md="6">
        <ul>
          <li v-for="link in links" :key="link.i">
            <router-link class="white--text" :to="link.to">{{
              link.text
            }}</router-link>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { text: "Home", to: "/" },
        { text: "About", to: "/about" },
        { text: "Careers", to: "/careers" },
        { text: "Residential", to: "/our-work/residential" },
        { text: "Commercial", to: "/our-work/commercial" },
        { text: "Public Works", to: "/our-work/public-works" },
        {
          text: "Architectural Woodwork",
          to: "/our-work/architectural-woodwork",
        },
        { text: "Woodwork", to: "/jlr-woodwork" },
        { text: "Contact Us", to: "/contact" },
      ],
      socials: {}
    };
  },
  methods: {
    openSocial(link) {
      window.open(link, "_blank");
    },
  },
  watch: {
    $route() {
      if (this.$route.name == 'Woodshop') {
        this.socials = this.$store.state.socialsWoodwork
      }
      else {
        this.socials = this.$store.state.socialsContractors
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  columns: 2;
  li {
    list-style: none;
    padding: 10px;
  }
}

a {
  text-decoration: none;
}
</style>
