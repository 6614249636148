<template>
  <v-app class="main_app">
    <v-app-bar
      app
      fixed
      shrink-on-scroll
      class="secondary nav"
      scroll-threshold="500"
      fade-img-on-scroll
      height="70"
      mobile-break-point="1100"
    >
      <v-img
        src="@/assets/JLR-Horizontal-White.svg"
        @click="openHome"
        max-width="250"
        height="40"
        class="my-auto"
        id="logo"
      />
      <v-spacer />
      <v-menu v-if="isAuthenticated" bottom left close-on-click offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="my-auto" dark v-bind="attrs" v-on="on">
            <v-icon color="primary">mdi-account-hard-hat</v-icon>
          </v-btn>
        </template>

        <v-list class="py-0">
          <v-list-item
            :to="item.to"
            v-for="(item, index) in admin"
            :key="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <NavBtns v-if="$vuetify.breakpoint.mdAndUp" class="my-auto" />
      <v-icon v-else color="white" class="my-auto" @click="nav = !nav">
        mdi-menu
      </v-icon>
    </v-app-bar>

    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-navigation-drawer width="300" dark right app fixed v-model="nav">
        <v-list dense nav class="mt-16 pt-6">
          <v-list-item
            :to="item.to"
            v-for="item in items"
            :key="item.title"
            link
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Our Work</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :to="item.to"
              class="pl-0"
              v-for="(item, i) in ourWork"
              :key="i"
              link
            >
              <v-list-item-title class="pl-6 link">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
        <template v-slot:append>
          <div class="pa-2 mb-3">
            <v-btn
              v-if="isAuthenticated"
              block
              color="primary darken-1"
              to="/login"
            >
              Logout
            </v-btn>
            <v-btn v-else block color="primary darken-1" to="/login">
              Login
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>
    </div>

    <v-main class="secondary pt-16 mt-2">
      <router-view class="pr-0 mt-2" />
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import NavBtns from "@/components/Nav/Buttons";
import Footer from "@/components/Page/Footer";
import { mapState } from "vuex";
export default {
  name: "App",
  components: {
    NavBtns,
    Footer,
  },

  data: () => ({
    loggedIn: true,
    items: [
      { title: "Home", to: "/" },
      { title: "About", to: "/about" },
      { title: "Careers", to: "/careers" },
      { title: "Sub List", to: "/bid-list" },
      { title: "Contact", to: "/contact" },
    ],
    admin: [
      { title: "Projects", to: "/all-projects", icon: "mdi-store" },
      { title: "Add Project", to: "/project-edit", icon: "mdi-store-plus" },
      {
        title: "Job Applications",
        to: "/applications",
        icon: "mdi-card-account-details-outline",
      },
      {
        title: "Add Job Posting",
        to: "/career-edit",
        icon: "mdi-folder-multiple-plus",
      },
      {
        title: "Manage Employees",
        to: "/employees",
        icon: "mdi-account-group-outline",
      },
    ],
    ourWork: [
      { title: "Residential", to: "/our-work/residential" },
      { title: "Commercial", to: "/our-work/commercial" },
      { title: "Public Works", to: "/our-work/public-works" },
      {
        title: "Architectural Woodwork",
        to: "/our-work/architectural-woodwork",
      },
    ],
    nav: true,
  }),
  methods: {
    openHome() {
      this.$router.push("/").catch(() => {});
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    ...mapState(["userProfile", "isAuthenticated"]),
  },
};
</script>

<style lang="scss" scoped>
.nav {
  z-index: 999 !important;
}

#logo {
  cursor: pointer;
}
::v-deep .v-main__wrap {
  margin-top: -70px;
}

::v-deep .full {
  height: 99vh;
  min-height: 99vh;
}
</style>

<style lang="scss">
* {
  scrollbar-width: thin;
  scrollbar-color: #fff rgb(190, 190, 190);
}
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background: #fff;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 20px;
}
</style>
